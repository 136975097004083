import React from 'react';
import { useTranslation } from 'next-i18next';
import type { PageId, TemplateData } from '../types';
import { Text } from '@imus/services-ui';
import TabItem from './TabItem';
import { tabsRowStyle, rowStyle } from './styles';

type Props = {
  page: PageId;
  data: TemplateData;
  onNavigation?: (event: React.MouseEvent<HTMLElement>, page: PageId) => void;
  baseUrl: string;
};

const TabsRow: React.FC<Props> = ({ page, baseUrl, data, onNavigation }) => {
  const { t } = useTranslation();

  const hasNavigation =
    data.artist?.enabled || data.tour?.enabled || data.press?.enabled;

  if (!hasNavigation || data.free) {
    return (
      <div css={rowStyle}>
        <Text.H2>{t('listen')}</Text.H2>
      </div>
    );
  }

  return (
    <div css={tabsRowStyle}>
      <TabItem
        to={baseUrl + '/'}
        label={t('listen')}
        isActive={page === 'release'}
        onClick={
          onNavigation
            ? (event) => onNavigation && onNavigation(event, 'release')
            : undefined
        }
        dataComponent="nav-link"
      />

      {data.artist?.enabled && (
        <TabItem
          to={baseUrl + '/artist'}
          isActive={page === 'artist'}
          label={t('artist')}
          onClick={
            onNavigation
              ? (event) => onNavigation && onNavigation(event, 'artist')
              : undefined
          }
          dataComponent="nav-link"
        />
      )}

      {data.tour?.enabled && (
        <TabItem
          to={baseUrl + '/tour'}
          label={t('tour')}
          isActive={page === 'tour'}
          onClick={
            onNavigation
              ? (event) => onNavigation && onNavigation(event, 'tour')
              : undefined
          }
          dataComponent="nav-link"
        />
      )}

      {data.press?.enabled && (
        <TabItem
          to={baseUrl + '/press'}
          label={t('press')}
          isActive={page === 'press'}
          onClick={
            onNavigation
              ? (event) => onNavigation && onNavigation(event, 'press')
              : undefined
          }
          dataComponent="nav-link"
        />
      )}
    </div>
  );
};

export default TabsRow;
