import { css } from '@emotion/react';

const buttonShape = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  transition: opacity 0.15s ease-in-out;
  background-color: var(--bg-1);
  box-shadow: inset 0 0 0 1px var(--fg-4);

  @media (prefers-color-scheme: dark) {
  }

  :hover {
    opacity: 0.75;
  }
`;

export const iconButtonStyle = [
  buttonShape,
  css`
    padding: 8px;
    width: 40px;
    height: 40px;

    > svg {
      fill: var(--fg-1);
    }
  `,
];

export const buttonStyle = [
  buttonShape,
  css`
    /* Block properties */
    gap: 8px;
    padding: 0px 16px;
    height: 40px;

    /* Text properties */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--fg-1);
  `,
];
