import React from 'react';
import { LinkButton } from '../Button';
import * as Text from '../Text';
import { IMusicianLogotype } from '../Icons/logotype';
import {
  brandingStyle,
  artistHubIconStyle,
  textColumnStyle,
  titleRowStyle,
  imdLinkStyle,
  brandingButtonStyle,
} from './styles';
import { ArtistHubIcon } from '@imus/base-ui';

export const Branding = ({
  imusicianUrl,
  description,
  callToAction,
  callToActionUrl,
  title,
}: {
  imusicianUrl: string;
  description: string;
  callToAction: string;
  callToActionUrl: string;
  title: string;
}) => {
  return (
    <div css={brandingStyle}>
      <ArtistHubIcon css={artistHubIconStyle} />

      <div css={textColumnStyle}>
        <div css={titleRowStyle}>
          <Text.ContentM>{title}</Text.ContentM>
          <a
            css={imdLinkStyle}
            data-component="imusician-link"
            target="_blank"
            href={imusicianUrl || '/'}
            rel="noreferrer"
          >
            <IMusicianLogotype style={{ height: '18px' }} />
          </a>
        </div>

        <Text.BodyS>{description}</Text.BodyS>
      </div>

      <LinkButton
        css={brandingButtonStyle}
        data-component="imusician-signup-link"
        href={callToActionUrl || '/'}
        target="_blank"
      >
        {callToAction}
      </LinkButton>
    </div>
  );
};
