import React from 'react';
import type { TemplateData } from '../types';
import SocialIcon from './SocialIcon';
import { socialWrapperStyle } from './styles';

type Props = {
  data: TemplateData;
};

const Social: React.FC<Props> = ({ data }) => {
  if (!data.general?.social || data.free) {
    return null;
  }

  return (
    <div css={socialWrapperStyle}>
      {data.general.social?.map(({ name, url }) =>
        name && url ? (
          <SocialIcon key={name} provider={name} url={url} />
        ) : undefined
      )}
    </div>
  );
};

export default Social;
