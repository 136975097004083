import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Text, Branding } from '@imus/services-ui';
import type { PageId, TemplateData } from '../types/TemplateData';
import {
  scrollWrapperStyle,
  contentSectionStyle,
  navSectionStyle,
  childrenWrapperStyle,
  avatarStyle,
  avatarSection,
  socialDesktop,
  socialMobile,
  artistPageProductLineDesktop,
  contentFooter,
  artistPageProductLineMobile,
} from './styles';
import { css } from '@emotion/react';
import { useTranslation, Trans } from 'next-i18next';
import { BaseUrlContext } from '../BaseUrlContext';
import { getFileUrl } from '../utils/getFileUrl';
import { ArtistAvatarPlaceholder } from '@imus/base-ui';

type Props = {
  data: TemplateData;
  page: PageId;
  children: React.ReactNode;
  onNavigation?: (event: React.MouseEvent<HTMLElement>, page: PageId) => void;
  baseUrl?: string;
  fileBaseUrl?: string;
};

const navLinkStyle = [
  css`
    color: var(--fg-1);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    opacity: 0.4;
    @container artist-template-sizer (width > 1024px) {
      padding: 10px;
      width: 100%;
      opacity: 0.7;
      border-radius: 8px;
      background: var(--bg-1);

      &:hover {
        opacity: 1;
      }

      @media (prefers-color-scheme: dark) {
        background-color: rgba(51, 51, 51, 0.5);
        &:hover {
          background: var(--bg-1);
        }
      }
    }
    &:hover {
      opacity: 1;
    }
  `,
];
const activeLinkStyle = css`
  opacity: 1;
  @container artist-template-sizer (width > 1024px) {
    box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    opacity: 1;
    background: var(--bg-1);
  }
`;

const linksWrapperStyle = css`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  @container artist-template-sizer (width > 1024px) {
    max-width: 168px;
    width: 100%;
    gap: 16px;
  }
`;

export const Layout: React.FC<Props> = ({
  data,
  baseUrl = '',
  fileBaseUrl = '',
  page,
  children,
  onNavigation,
}) => {
  const { t } = useTranslation();
  const [navigatedTo, setNavigatedTo] = useState<string>();
  useEffect(() => {
    setNavigatedTo(page);
  }, [page]);
  const pageSelected = navigatedTo || page;
  const avatarSrc = data.profileImage
    ? getFileUrl(data.profileImage, fileBaseUrl)
    : undefined;

  return (
    <>
      <BaseUrlContext.Provider value={fileBaseUrl}>
        {' '}
        <div css={scrollWrapperStyle}>
          <div css={navSectionStyle}>
            <div css={avatarSection}>
              {avatarSrc ? (
                <Image
                  alt="avatar"
                  src={avatarSrc}
                  css={avatarStyle}
                  width={140}
                  height={140}
                />
              ) : (
                <ArtistAvatarPlaceholder css={avatarStyle} />
              )}
              <Text.H3 data-test-id="ReleasePageTemplate-Title">
                {data.name}
              </Text.H3>
            </div>
            <div css={linksWrapperStyle}>
              <a
                onClick={(e) => {
                  if (onNavigation) {
                    onNavigation(e, 'about');
                    setNavigatedTo('about');
                  }
                }}
                css={[
                  navLinkStyle,
                  pageSelected === 'about' && activeLinkStyle,
                ]}
                href={`${baseUrl}`}
              >
                {t('about')}
              </a>
              {!!data.releases.length && (
                <a
                  onClick={(e) => {
                    if (onNavigation) {
                      onNavigation(e, 'releases');
                      setNavigatedTo('releases');
                    }
                  }}
                  css={[
                    navLinkStyle,
                    pageSelected === 'releases' && activeLinkStyle,
                  ]}
                  href={`${baseUrl}/releases`}
                >
                  {t('releases')}
                </a>
              )}
              {data.tour.enabled &&
              (data.tour.events?.length || data.tour.splash) ? (
                <a
                  onClick={(e) => {
                    if (onNavigation) {
                      onNavigation(e, 'tour');
                      setNavigatedTo('tour');
                    }
                  }}
                  css={[
                    navLinkStyle,
                    pageSelected === 'tour' && activeLinkStyle,
                  ]}
                  href={`${baseUrl}/tour`}
                >
                  {t('tour')}
                </a>
              ) : null}
              {data.press.enabled &&
              (data.press.quotes?.length ||
                data.press.contacts?.length ||
                data.press.pressFiles?.length) ? (
                <a
                  onClick={(e) => {
                    if (onNavigation) {
                      onNavigation(e, 'press');
                      setNavigatedTo('press');
                    }
                  }}
                  css={[
                    navLinkStyle,
                    pageSelected === 'press' && activeLinkStyle,
                  ]}
                  href={`${baseUrl}/press`}
                >
                  {t('press')}
                </a>
              ) : null}
            </div>

            <div css={socialDesktop}>
              {data.branded ? (
                <Branding
                  callToActionUrl={t('signup-now-link')}
                  callToAction={t('signup-now')}
                  description={t('branding-description')}
                  imusicianUrl={t('imusician-link')}
                  title={t('powered-by')}
                ></Branding>
              ) : (
                <div css={artistPageProductLineDesktop}>
                  <a href={t('imusician-link') || ''}>iMusician</a>
                  <a href={t('artist-hub-link') || ''}>{t('artist-hub')}</a>
                </div>
              )}
            </div>
          </div>

          <div
            css={contentSectionStyle}
            data-test-id="ContentSection"
            data-section={pageSelected}
          >
            <div css={childrenWrapperStyle}>
              {children}

              <div css={contentFooter}>
                <div>
                  <Text.BodyS>
                    <Trans
                      values={{ name: data.name }}
                      i18nKey="imprint-text"
                      defaults="All Content is from the {{name}} - <0>Imprint</0>, <1>TC</1>"
                      components={[
                        <a key="1" href={t('artist-hub-imprint-link') || ''}>
                          Imprint
                        </a>,
                        <a key="1" href={t('artist-hub-terms-link') || ''}>
                          TC
                        </a>,
                      ]}
                    ></Trans>
                  </Text.BodyS>
                </div>
                <div>
                  <Text.BodyS>© {new Date().getFullYear()}</Text.BodyS>
                </div>
                {!data.branded && (
                  <div css={artistPageProductLineMobile}>
                    <a href={t('imusician-link') || ''}>iMusician</a>
                    <a href={t('artist-hub-link') || ''}>{t('artist-hub')}</a>
                  </div>
                )}
              </div>
            </div>
          </div>

          {data.branded ? (
            <div css={socialMobile}>
              <Branding
                callToActionUrl={t('signup-now-link')}
                callToAction={t('signup-now')}
                description={t('branding-description')}
                imusicianUrl={t('imusician-link')}
                title={t('powered-by')}
              ></Branding>
            </div>
          ) : null}
        </div>
      </BaseUrlContext.Provider>
    </>
  );
};
