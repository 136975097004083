import { css } from '@emotion/react';

export const rowStyle = css`
  display: flex;
  gap: 32px;
  padding: 40px 40px 0 40px;

  @container template-sizer (width < 512px) {
    justify-content: center;
  }
`;

export const tabsRowStyle = css`
  ${rowStyle}
  padding: 16px 40px;
  box-shadow: inset 0 -1px 0 0 var(--fg-4);
`;

export const tabItemStyle = css`
  padding: 16px 0;
  opacity: 0.4;

  @container template-sizer (width < 512px) {
    padding: 8px 0;

    span {
      font-size: 16px;
      line-height: 20px;
    }
  }

  :visited {
    color: inherit;
  }
`;

export const tabItemActiveStyle = css`
  opacity: 1;
`;
