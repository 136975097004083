import React from 'react';
import { Icons } from '@imus/services-ui';
import type { SocialProviders } from '../types';
import { socialIconStyle } from './styles';

type Props = {
  provider: SocialProviders;
  url: string;
};

const SocialIcon: React.FC<Props> = ({ provider, url }) => (
  <a
    css={socialIconStyle}
    data-component="social-link"
    data-social={provider}
    target="_blank"
    rel="noreferrer"
    href={url}
  >
    <Icons.Icon icon={provider} />
  </a>
);

export default SocialIcon;
