import { css } from '@emotion/react';

export const scrollWrapperStyle = css`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  background-color: var(--bg-4);
  overflow: auto;

  a {
    text-decoration: none;
  }

  @container artist-template-sizer (width > 1024px) {
    flex-direction: row;
    overflow: unset;
  }
`;

export const avatarSection = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const avatarStyle = css`
  width: 80px;
  object-fit: cover;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  margin-bottom: 16px;
  background-color: var(--bg-1);
  border: 1px solid var(--fg-4);

  @container artist-template-sizer (width > 1024px) {
    width: 140px;
    height: 140px;
    margin-bottom: 24px;
  }
`;

export const navSectionStyle = css`
  display: flex;
  flex-direction: column;
  container-type: inline-size;
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 32px 16px 16px;
  gap: 32px;

  margin: 0 auto;
  max-width: 460px;
  justify-self: center;
  @container artist-template-sizer (width > 1024px) {
    max-width: 768px;
    padding: 64px 16px 16px;
    flex: none;
    width: 360px;
    overflow: auto;
    scrollbar-gutter: stable;
  }
`;

export const coverContentStyle = css`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;

  @container artist-template-sizer (width > 512px) {
    text-align: left;
  }

  @container artist-template-sizer (width > 1024px) {
    text-align: left;
  }
`;

export const coverTextStyle = css`
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @container artist-template-sizer (width > 1024px) {
    gap: 16px;
  }
`;

export const socialMobile = css`
  z-index: 3;
  position: sticky;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(242, 242, 242, 0) 0%,
    rgba(242, 242, 242, 1) 100%
  );
  padding: 16px 16px 0;
  & > div {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (prefers-color-scheme: dark) {
    background: linear-gradient(
      180deg,
      rgba(34, 34, 34, 0) 0%,
      rgba(34, 34, 34, 1) 100%
    );
  }
  @container artist-template-sizer (width > 1024px) {
    display: none;
  }
`;

export const socialDesktop = css`
  display: flex;
  z-index: 1;
  margin-top: auto;
  width: 100%;
  @container artist-template-sizer (width < 1024px) {
    display: none;
  }
`;
export const contentFooter = css`
  display: flex;
  z-index: 1;
  margin: auto 0 16px;
  padding-top: 32px;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 24px;
  a {
    color: var(--fg-1);
  }
`;

const artistPageProductLine = css`
  display: flex;
  gap: 16px;
  margin: 0 auto 16px auto;
  font-family: 'Space Grotesk', 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  display: none;

  font-feature-settings:
    'cv11' on,
    'ss01' on;
  a:nth-of-type(1) {
    color: var(--fg-1);
  }
  a:nth-of-type(2) {
    color: var(--fg-3);
  }
`;

export const artistPageProductLineDesktop = css`
  ${artistPageProductLine}
  display: none;
  line-height: 24px;

  @container artist-template-sizer (width > 1024px) {
    display: flex;
  }
`;

export const artistPageProductLineMobile = css`
  ${artistPageProductLine}
  display: none;
  margin: 0;
  flex: 1;

  @container artist-template-sizer (width < 1024px) {
    display: flex;
  }
`;

export const contentSectionStyle = css`
  // use flex: 1 for [mobile] to always keep the branding at the bottom, no
  // matter the content length
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px;

  @container artist-template-sizer (width > 1024px) {
    text-align: left;
    padding: 64px 32px 16px;
    overflow: auto;
  }
`;

export const childrenWrapperStyle = css`
  // use flex: 1 for [mobile] and [desktop] to always keep the branding at the
  // bottom, no matter the content length
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  gap: 32px;
  max-width: 480px;

  @container artist-template-sizer (width > 1024px) {
    text-align: left;
    max-width: 768px;
  }
`;
