import React from 'react';
import { buttonStyle } from './styles';

type Props = React.ComponentProps<'a'>;

const LinkButton: React.FC<Props> = ({ ...props }) => (
  <a css={buttonStyle} {...props} />
);

export default LinkButton;
