import React from 'react';
import { Text } from '@imus/services-ui';
import { tabItemStyle, tabItemActiveStyle } from './styles';

type Props = {
  to: string;
  label: string;
  isActive: boolean;
  dataComponent?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const TabItem: React.FC<Props> = ({ to, label, isActive, onClick }) => (
  <a
    css={[tabItemStyle, isActive && tabItemActiveStyle]}
    data-component="nav-link"
    href={to}
    onClick={onClick}
  >
    <Text.H3 as="span">{label}</Text.H3>
  </a>
);

export default TabItem;
