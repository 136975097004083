import React from 'react';
import * as Social from './social';
import * as App from './app';
import * as Shop from './shops';
export { PlayIcon, PauseIcon } from '@imus/base-ui';

/** Icons that use single paths and 24/24 viewport */
const Icons: Record<string, string | React.ReactNode> = {
  soundcloud: Social.Soundcloud,
  bandcamp: Social.Bandcamp,
  spotify: Social.Spotify,
  deezer: Social.Deezer,
  apple_music: Social.AppleMusic,
  // TODO: Delete on September 2021
  appleMusic: Social.AppleMusic,
  facebook: Social.Facebook,
  twitter: Social.Twitter,
  instagram: Social.Instagram,
  youtube: Social.YouTube,
  // app icons
  chevron: App.Chevron,
  chevronLeft: App.ChevronLeft,
  download: App.Download,
  link: (
    <>
      <path d="M7 6H9V8H7C4.79086 8 3 9.79086 3 12C3 14.2091 4.79086 16 7 16H9V18H7C3.68629 18 1 15.3137 1 12C1 8.68629 3.68629 6 7 6Z" />
      <path d="M21 12C21 14.2091 19.2091 16 17 16H15V18H17C20.3137 18 23 15.3137 23 12C23 8.68629 20.3137 6 17 6H15V8H17C19.2091 8 21 9.79086 21 12Z" />
      <path d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8Z" />
    </>
  ),
};

/** Shop images that can use multiple paths and 104/24 viewport */
const ShopIcons: Record<string, React.ReactNode> = {
  '7digital': Shop.SevenDigital,
  amazonmusic: Shop.AmazonMusic,
  amazonmusicdownload: Shop.AmazonMusic,
  anghami: Shop.Anghami,
  applemusic: Shop.AppleMusic,
  awa: Shop.Awa,
  beatport: Shop.Beatport,
  claromusica: Shop.Claromusica,
  deezer: Shop.Deezer,
  itunes: Shop.iTunesStore,
  kkbox: Shop.Kkbox,
  pandora: Shop.Pandora,
  spotify: Shop.Spotify,
  qobuz: Shop.Qobuz,
  qobuzdownload: Shop.Qobuz,
  tidal: Shop.Tidal,
  traxsource: Shop.Traxsource,
  youtube: Shop.YouTube,
  youtubemusic: Shop.YouTubeMusic,
  junodownload: Shop.Juno,
  custom: Shop.Custom,
};

export const ShopIcon = ({
  icon,
  style,
  className,
}: {
  icon: string;
  style?: Record<string, unknown>;
  className?: string;
}) => (
  <svg
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 104 24"
  >
    {ShopIcons[icon]}
  </svg>
);

export const Icon = ({
  icon,
  style,
  className,
}: {
  icon: string;
  style?: Record<string, unknown>;
  className?: string;
  fallback?: string;
}): JSX.Element => (
  <svg
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    {typeof Icons[icon] === 'string' ? (
      <path d={Icons[icon] as string} />
    ) : (
      Icons[icon]
    )}
  </svg>
);
