import React, { useCallback, useState } from 'react';
import { Text, Branding } from '@imus/services-ui';
import TabsRow from '../TabsRow';
import Social from '../Social';
import type { PageId, TemplateData } from '../types/TemplateData';
import Image from 'next/image';
import {
  brandingOverlayStyle,
  scrollWrapperStyle,
  contentSectionStyle,
  coverSectionStyle,
  coverArtworkStyle,
  coverContentStyle,
  coverTextStyle,
  childrenWrapperStyle,
} from './styles';
import { useTranslation } from 'next-i18next';

type Props = {
  data: TemplateData;
  page: PageId;
  children: React.ReactNode;
  onNavigation?: (event: React.MouseEvent<HTMLElement>, page: PageId) => void;
  baseUrl?: string;
  style?: React.CSSProperties;
};

export const Layout: React.FC<Props> = ({
  data,
  style,
  baseUrl = '',
  page,
  children,
  onNavigation,
}) => {
  const { t } = useTranslation();
  const [navigatedTo, setNavigatedTo] = useState<PageId>();
  const [coverUrlOptimized, setCoverUrlOptimized] = useState<string>();
  const pageSelected = navigatedTo || page;
  const hnandleNavigation = useCallback(
    (event: React.MouseEvent<HTMLElement>, page: PageId) => {
      setNavigatedTo(page);
      if (onNavigation) {
        onNavigation(event, page);
      }
    },
    [onNavigation]
  );
  const coverUrl = data.release.cover?.url || '';

  return (
    <div
      style={style}
      css={scrollWrapperStyle}
      data-page-template={`release-page-template-${
        data.free ? 'non-premium' : 'premium'
      }`}
    >
      <div
        css={coverSectionStyle}
        style={{
          backgroundImage: `url(${coverUrlOptimized})`,
        }}
      >
        <Image
          alt="cover"
          width={800}
          priority={true}
          height={800}
          onLoadingComplete={(img) => {
            setCoverUrlOptimized(img.src);
          }}
          css={coverArtworkStyle}
          src={coverUrl}
        />

        <div css={coverContentStyle}>
          <div css={coverTextStyle}>
            <Text.CoverTitle data-test-id="ReleasePageTemplate-Title">
              {data.release.title}
            </Text.CoverTitle>

            <Text.CoverSubtitle>
              {data.release.displayArtist}
            </Text.CoverSubtitle>
          </div>

          <Social data={data} />
        </div>
      </div>

      <div
        css={contentSectionStyle}
        data-test-id="ContentSection"
        data-section={pageSelected}
      >
        <TabsRow
          onNavigation={hnandleNavigation}
          baseUrl={baseUrl}
          page={pageSelected}
          data={data}
        />
        <div css={childrenWrapperStyle}>{children}</div>
        {(data.free || data.branded) && (
          <div css={brandingOverlayStyle}>
            <Branding
              callToActionUrl={t('signup-now-link')}
              callToAction={t('signup-now')}
              description={t('branding-description')}
              imusicianUrl={t('imusician-link')}
              title={t('powered-by')}
            />
          </div>
        )}
      </div>
    </div>
  );
};
