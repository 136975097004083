import { css } from '@emotion/react';

export const brandingStyle = css`
  --upsell-outline: #b151c9;
  --upsell-button-fg: #fff;
  --upsell-button-bg: #b151c9;

  display: flex;
  gap: 24px;
  align-items: center;
  padding: 24px;
  border-radius: 12px;
  background-color: var(--bg-1);
  width: 100%;
  box-shadow: 0 0 0 1px var(--upsell-outline);

  svg {
    width: 56px;
  }

  @container (width < 512px) {
    flex-direction: column;
    gap: 16px;
    padding: 16px;
  }
`;

export const artistHubIconStyle = css`
  border-radius: 50%;
  @container (width < 512px) {
    display: none;
  }
`;

export const textColumnStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  svg {
    fill: var(--fg-1);
  }

  @container (width < 512px) {
    align-items: center;
  }
`;

export const imdLinkStyle = css`
  display: flex;
`;

export const titleRowStyle = css`
  display: flex;
  column-gap: 6px;
  align-items: self-end;
  flex-wrap: wrap;
`;

export const brandingButtonStyle = css`
  background: var(--upsell-button-bg);
  color: var(--upsell-button-fg);

  @container (width < 512px) {
    width: 100%;
  }
`;
